<template>
	<div>
		<div class="row">
			<div class="col-sm-12 my-4 text-right">
				<h1>Acciones</h1>
			</div>
		</div>

		<DataGrid :config="dataGridConfig" :data="actividades" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div class="col-sm-2">
					<button class="btn secondary-btn" @click="ver_detalles">Ver detalles</button>
				</div>
				<div class="col-sm-2">
					<button class="btn warning-btn" @click="obtener_actividades">Recargar</button>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="accion.nombre" :options="{width: '40vw'}" @close="cerrar_modal">
			<div class="title">Acción</div>
			<div class="body">
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Usuario</label>
					<div class="col-sm-10">{{ accion.nombre }}</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Acción</label>
					<div class="col-sm-10">{{ accion.accion}}</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Datos</label>
					<div class="col-sm-10"><pre>{{ JSON.stringify(JSON.parse(accion.payload), null, 4) }}</pre></div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-9"><button class="btn secondary-btn" @click="cerrar_modal">Aceptar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'

	import api from '@/apps/clientes/api/clientes'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'accesos'
					,cols: {
						id: 'ID'
						,nombre: 'Usuario'
						,accion: 'Acción realizada'
						,payload: 'Datos'
						,created_at: 'Fecha de ingreso'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						payload: function(val) {
							let tamanio = 100;
							return val.length <= tamanio ? val : val.substr(0,tamanio)+'...';
						}
						,created_at: function(val,row,vue) {
							return vue.$moment(val).format('YYYY-MM-DD hh:mm:ss')
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionadas: []
				,seleccionar: false
				,actividades: []
				,accion: {
					nombre: null
					,accion: null
					,payload: null
					,created_at: null
				}
			}
		}
		,methods: {
			obtener_actividades: async function() {
				let res = (await api.listar_actividades(this.options)).data;

				this.actividades = res.data;
				this.dataGridConfig.paginator.pagina_actual = res.current_page;
				this.dataGridConfig.paginator.total_registros = res.total;
				this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
			}
			,dataGridActions: function(tipo, data) {
				this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.options = data;
					this.obtener_actividades();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,ver_detalles: function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar una entrada','error','alert');

				this.accion = this.seleccionadas[0];
			}
			,cerrar_modal: function() {
				this.seleccionar = false;
				this.accion = {
					nombre: null
					,accion: null
					,payload: null
					,created_at: null
				}
			}
		}
	}
</script>